import { render, staticRenderFns } from "./ScoreJiLu.vue?vue&type=template&id=cdd6d63e&scoped=true&"
import script from "./ScoreJiLu.vue?vue&type=script&lang=js&"
export * from "./ScoreJiLu.vue?vue&type=script&lang=js&"
import style0 from "./ScoreJiLu.vue?vue&type=style&index=0&id=cdd6d63e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdd6d63e",
  null
  
)

export default component.exports