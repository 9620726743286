<template>
  <div class="score-history">
    <el-row style="margin: 15px 0;">
      <el-col :span="12">
        <el-radio-group v-model="radio">
          <el-radio :label="1">周考成绩</el-radio>
          <el-radio :label="2">作业成绩</el-radio>
          <el-radio :label="3">项目成绩</el-radio>
          <el-radio :label="4">每日一考</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        <el-input v-if="radio != 4" size="mini" placeholder="请输入学员在OA中预留的手机号" v-model="tel" clearable>
        </el-input>
        <el-input v-else size="mini" placeholder="请输入学员在OA中的学号" v-model="xuehao" clearable>
        </el-input>
      </el-col>
      <el-col :span="4" :offset="1">
        <el-button type="primary" size="mini" @click="startCheck">查询</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-for="item in stus" :key="item.id" :span="4">
        <StuCheckCard v-if="radio == 1" :title="item.stu.stu_name" :bukao="item.bukao"
          :val="item.chengji ? item.chengji : 0"></StuCheckCard>

        <div v-else-if="radio == 3" :class="[
          'stu',
          {
            you: item.jibie >= 90,
            liang: item.jibie >= 60 && item.jibie < 90,
            cha: item.jibie < 60,
          },
        ]">
          <h3>{{ item.stu_name }}</h3>
          <p>{{ item.bjc[0].xm_name }}</p>
          <el-input placeholder="进度" v-model="item.jibie" clearable size="mini" :readonly="true"></el-input>
        </div>

        <div v-else-if="radio == 2" :class="[
          'stu',
          {
            you: item.jibie >= 90,
            liang: item.jibie >= 60 && item.jibie < 90,
            cha: item.jibie < 60,
          },
        ]">
          <h3>{{ item.stu_name }}</h3>
          <p>{{ item.kecheng_name }}</p>
          <el-input placeholder="进度" v-model="item.jibie" clearable size="mini" :readonly="true"></el-input>
        </div>
        <StuScore v-else :title="item.studentName" :des="item.score"></StuScore>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import StuCheckCard from "@/components/StuCheckCard.vue";
import StuScore from "@/components/StuScore.vue";
export default {
  data() {
    return {
      radio: 1,
      tel: null,
      xuehao: null,
      stus: []
    }
  },
  components: {
    StuCheckCard,
    StuScore
  },
  watch: {
    radio() {
      this.stus = [];
    }
  },
  methods: {
    async startCheck() {
      if (this.radio != 4 && /^1\d{10}$/.test(this.tel)) {
        let url = this.radio == 1 ? '/exam/zkCheckByTel/' : (this.radio == 2 ? "/exam/zyCheckByTel/" : "/exam/xmCheckByTel/");
        this.$datas.meta = { url, tel: this.tel };
        let res = await this.$datas.getHistoryScores;
        this.stus = res.data;
      } else if (this.radio != 4 && !/^1\d{10}$/.test(this.tel)) {
        this.$alert('手机号不合法', {
          confirmButtonText: '确定'
        });
      } else {
        this.$datas.meta = { xuehao: this.xuehao };
        let res = await this.$datas.jdScore;
        console.log(res);
        if (res.data.code == 200) {
          this.stus = res.data.msg.data;
        } else {
          this.$alert('未查到每日一考记录，请核实学生学号', {
            confirmButtonText: '确定'
          });
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.stu {
  width: 120px;
  padding: 5px 10px;
  border: 1px solid #999;
  border-radius: 5px;
  float: left;
  margin: 10px;

  h3 {
    margin-bottom: 5px;
    cursor: pointer;
  }

  p {
    padding: 0 0 5px;
  }
}

.liang {
  background-color: #409eff;
  color: white;
  border: 1px solid #409eff;
}

.cha {
  background-color: #f56c6c;
  color: white;
  border: 1px solid #f56c6c;
}

.infor {
  display: flex;
  justify-content: space-between;
  align-content: center;
  color: #555;
}
</style>